import About from "./components/About";
import Contact from "./components/Contact";
import Industries from "./components/Industries";
import Services from "./components/Services"
import Home from "./components/Home";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div>
      <NavBar />
      <Home />
      <About />
      <Services/>
      <Industries />
      <Contact />
    </div>
  );
}

export default App;